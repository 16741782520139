import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import type { Language } from '@ruokaboksi/api-client';
import posthog from 'posthog-js';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    recipeCount: {
      type: Number,
      required: true,
    },
    minRecipes: {
      type: Number,
      required: true,
    },
    maxRecipes: {
      type: Number,
      required: true,
    },
    additionalProductsCount: {
      type: Number,
      required: true,
    },
    isSelectedDeliveryWeekPaused: {
      type: Boolean,
      required: true,
    },
    isSelectedDeliveryWeekPauseEditable: {
      type: Boolean,
      required: true,
    },
    isRecipe: { type: Boolean, required: true },
    isSelectedDeliveryWeekEditable: { type: Boolean, required: true },
    additionalRecipePriceDifference: { type: Number, required: true },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const { formatPrice } = useCustomerCurrency();

    const selectedProducts = computed<VNode | string>(() => {
      if (
        props.isSelectedDeliveryWeekPaused &&
        props.isSelectedDeliveryWeekPauseEditable
      ) {
        return props.isRecipe
          ? t('selection_info.activate_to_select_recipes')
          : t('selection_info.activate_to_select_products');
      }

      if (
        props.isSelectedDeliveryWeekPaused &&
        !props.isSelectedDeliveryWeekPauseEditable
      ) {
        return t('selection_info.paused');
      }

      if (props.additionalProductsCount) {
        return (
          <span>
            <i18n-t
              keypath="selection_info.recipes_and_products_selected"
              plural={props.additionalProductsCount}
              scope="global"
            >
              <b>{props.recipeCount}</b>
              <b>{props.additionalProductsCount}</b>
            </i18n-t>
          </span>
        );
      }

      return (
        <span>
          <i18n-t
            keypath="selection_info.recipes_selected"
            plural={props.recipeCount}
            scope="global"
          >
            <b>{props.recipeCount}</b>
          </i18n-t>
        </span>
      );
    });

    const selectedProductsLimit = computed<VNode | string>(() => {
      if (
        props.isSelectedDeliveryWeekPaused ||
        !props.isSelectedDeliveryWeekEditable ||
        !props.isRecipe
      ) {
        return '';
      }

      if (props.recipeCount < props.minRecipes) {
        return (
          <i18n-t keypath="selection_info.choose_min" scope="global">
            <b>{props.minRecipes}</b>
          </i18n-t>
        );
      }

      if (
        props.recipeCount > props.maxRecipes ||
        props.recipeCount === props.minRecipes
      ) {
        if (
          props.recipeCount === props.minRecipes &&
          props.additionalRecipePriceDifference
        ) {
          return (
            <span
              class={
                posthog.getFeatureFlag('extra-recipe-price-v2') === 'test' &&
                'bg-rb-yellow rounded-sm py-1 px-2 text-rb-dark-green whitespace-nowrap'
              }
            >
              <i18n-t
                keypath="selection_info.choose_min_extra_recipe_test"
                scope="global"
              >
                <b>
                  {getOrdinalNumber(props.maxRecipes, locale.value as Language)}
                </b>
                <span>
                  {formatPrice(props.additionalRecipePriceDifference)}
                </span>
              </i18n-t>
            </span>
          );
        }
        return (
          <i18n-t keypath="selection_info.choose_max" scope="global">
            <b>{props.maxRecipes}</b>
          </i18n-t>
        );
      }
      return '';
    });

    return {
      selectedProducts,
      selectedProductsLimit,
    };
  },

  render(): VNode | null {
    return (
      <p>
        {this.selectedProducts}{' '}
        <span class="lg:text-rb-yellow">{this.selectedProductsLimit}</span>
      </p>
    );
  },
});
